import { BasketParams } from './baskets.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './baskets.consts';
import { selectCurrentCampaignId } from 'store/campaigns';

export const fetchAllBasketsOfCampaign = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ campaignId }: { campaignId: number }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/baskets`,
      headers: {},
    });

    return response.data.map((basket: BasketParams) => ({
      ...basket,
      campaignId,
    }));
  }),
);

export const fetchAllBasketsForCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchAllBasketsForCurrentCampaign',
  withErrorHandling(async (_: any, thunkApi: any) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    const response = await adserver({
      url: `/campaign/${campaignId}/baskets`,
      headers: {},
    });

    return response.data.map((basket: BasketParams) => ({
      ...basket,
      campaignId,
    }));
  }),
);

export const removeAllBaskets = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
